<template>
  <div class="mt-4 px-4 container-fluid">
    <div class="breadcrumb text-md px-0 mb-4">
      <p><span class="breadcrumb-item" @click="irInicio">Inicio</span> > Pedidos</p>
    </div>
  </div>
  
  <div class="px-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card ficha-buscador">
          <div class="titulo"><h5 class="mx-4 mt-5 mb-5">Lista de Pedidos</h5></div>
          <div class="table-search">
            <soft-input id="textoBuscador" type="text" placeholder="Búsqueda rápida..." aria-label="Búsqueda rápida" @input="teclearBuscador"/>
          </div>
          <div class="descarga doble">
            <p @click="descargarCSV"><i class="fa fa-download" aria-hidden="true"></i></p>
          </div>
          <div class="borrados">
            <soft-switch id="borrados-switch" name="borrados" @change="cambiarBorrados" :checked="mostrarBorrados"></soft-switch>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="px-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card ficha-listado mt-4">
          <div class="px-4 pb-0 card-body">
            <div class="table-responsive">
              <table id="Pedidos-list" class="table table-flush table-listado">
                <thead class="thead-light">
                  <tr>
                    <th @click="sortTable('0')" data-type="date" data-format="DD/MM/YYYY" :class="sortColumn=='0' ? sortDirection : ''">Fecha</th>
                    <th @click="sortTable('1')" :class="sortColumn=='1' ? sortDirection : ''">Referencia</th>
                    <th @click="sortTable('2')" :class="sortColumn=='2' ? sortDirection : ''">Producto</th>
                    <th @click="sortTable('3')" :class="sortColumn=='3' ? sortDirection : ''" v-if="miRol==1">Tipster</th>
                    <th @click="sortTable('4')" :class="sortColumn=='4' ? sortDirection : ''">Precio</th>
                    <th @click="sortTable('5')" :class="sortColumn=='5' ? sortDirection : ''">Estado</th>
                    <th data-type="html" data-sortable="false" class="sin-orden">Acc.</th>
                  </tr>
                </thead>
                <tbody class="text-sm">
                  <!-- Ciclo a través de los elementos filtrados y paginados -->
                  <tr v-for="row in filas" :key="row.id">
                    <td>{{ row.fecha }}</td>
                    <td>{{ row.referencia }}</td>
                    <td>{{ row.producto }}</td>
                    <td v-if="miRol==1">{{ row.tipster }}</td>
                    <td>{{ row.precio }}</td>
                    <td>{{ row.estado }}</td>
                    <td v-html="row.acciones"></td>
                  </tr>
                  <tr v-if="filas.length==0"><td colspan="7">No hay registros que mostrar</td></tr>
                </tbody>
              </table>
            </div>
            <!-- Control de la Paginacion manual -->
            <div class="paginacion" v-if="filas.length>0">
              <p class="contador-listado">Mostrando {{ mostradosIni }} a {{ mostradosFin }} de {{ mostradosTotal }} registros</p>

              <ul class="pagination pagination-md" v-if="paginaTotal>1">
                <li class="page-item prev-page" v-if="paginaActual>1">
                  <a class="page-link" aria-label="Previous" @click="paginaActual=paginaActual-1;fetchAndSetPedidos();">
                    <i class="fa fa-angle-left" aria-hidden="true"></i>
                  </a>
                </li>
                <li v-for="index in rangoPaginas" :key="index" class="page-item" :class="index==paginaActual ? 'active disabled' : ''">
                  <a class="page-link" @click="paginaActual=index;fetchAndSetPedidos();">{{index}}</a>
                </li>
                <li class="page-item next-page" v-if="paginaActual<paginaTotal">
                  <a class="page-link" aria-label="Next" @click="paginaActual=paginaActual+1;fetchAndSetPedidos();">
                    <i class="fa fa-angle-right" aria-hidden="true"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Modal @cerrar-modal="cerrarModal" :modalActive="modalActive">
    <div class="modal-content">
      <!--<edita-Pedido-form v-if="tipoModal=='E'" @cerrar-modal="() => { this.modalActive=false;fetchAndSetPedidos();}" :key="PedidoEditId" :PedidoId="PedidoEditId" :PedidoNombre="PedidoEditName" :PedidoEnlace="PedidoEditEnlace" :PedidoPrecio="PedidoEditPrecio" :PedidoActivo="PedidoEditActivo" :PedidoTipsterId="PedidoEditTipsterId"></edita-Pedido-form>-->
    </div>
  </Modal>

</template>

<script>
import showSwal from "@/mixins/showSwal.js";
import SoftSwitch from "@/components/SoftSwitch.vue";
import SoftInput from "@/components/SoftInput.vue";
import Modal from './components/Modal.vue';
//import EditaPedidoForm from './components/EditaPedidoForm.vue';
import {globalFunctionsMixin} from '@/mixins/globalFunctions.js';

export default {
  name: "Pedidos",
  components: {
    Modal,
  //  EditaPedidoForm,
    SoftSwitch,
    SoftInput
  },
  mixins: [globalFunctionsMixin],
  data() {
    return {
      filas: [],
      PedidoId: 0,
      mensajeConfirmacion: '',
      tipoModal: '',
      PedidoEditId: 0,
      PedidoEditActivo: '',
      PedidoEditEnlace: '',
      PedidoEditName: '',
      PedidoEditPrecio: '',
      PedidoEditTipster: '',
      PedidoEditTipsterId: 0,
      textoBuscador: '',
      paginaActual: 0,
      paginaTotal: 0,
      mostrarBorrados: false,
      mostradosIni: 0,
      mostradosFin: 0,
      mostradosTotal: 0,
      sortColumn: 0,
      sortDirection: '',
      modalActive: false,
      miRol: localStorage.getItem("usuarioRolId")
    };
  },
  computed: {
    rangoPaginas() {
      let startPage = Math.max(this.paginaActual - 3, 1);
      let endPage = Math.min(this.paginaActual + 3,this.paginaTotal);
      let pages = [];
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }
      return pages;
    }
  },
  methods: {
    alert() {
      showSwal.methods.showSwal({
        type: "error",
        message: "This is a PRO feature.",
        width: 400,
      });
    },

    async initializeDataTable() {
      this.sortColumn=0;
      this.sortDirection='desc';
      this.textoBuscador = '';
      this.paginaActual = 1;
      await this.fetchAndSetPedidos();
    },

    sortTable(key) {
      // Alternar entre ascendente y descendente al hacer clic en el encabezado
      if (this.sortColumn === key) {
        this.sortDirection = this.sortDirection=='asc' ? 'desc' : 'asc';
      } else {
        this.sortColumn = key;
        this.sortDirection = 'asc';
      }
      this.fetchAndSetPedidos();
    },

    teclearBuscador(event) {
      this.textoBuscador = event.target.value;

      let searchTimeout;
      clearTimeout(searchTimeout);
      searchTimeout = setTimeout(() => {
        this.fetchAndSetPedidos();
      }, 500);
    },

    async fetchAndSetPedidos() {
      try {
        // Petición al servidor
        await this.$store.dispatch("auth/pedidos", { textoBuscador: this.textoBuscador, paginaActual: this.paginaActual, mostrarBorrados: this.mostrarBorrados, sortColumn: this.sortColumn, sortDirection: this.sortDirection  }); 
        const response = JSON.parse(localStorage.getItem("pedidos"));

        var estados = {'P': 'No finalizado', 'C': 'Confirmado'};

        // Procesar los datos y actualizarlos en el DataTable
        this.filas = response.data.map(row => ({
          referencia: row.referencia,
          producto: row.producto,
          precio: row.precio,
          tipster: row.tipster,
          estado: estados[row.estado],
          fecha: this.formatDate(row.fecha, 'DD/MM/YYYY HH:mm:ss'),
          acciones:  `<a href="#" class="editButton cursor-pointer me-3" data-bs-toggle="tooltip" title="Detalle del pedido">
                        <i class="fas fa-edit text-secondary" data-rel="${row.id}"></i>
                      </a>`
        }));

        this.paginaTotal = response.paginas;
        this.mostradosIni = (response.actual-1)*response.porpagina + 1;
        this.mostradosFin = Math.min(response.totales,response.actual*response.porpagina);
        this.mostradosTotal = response.totales;

        this.activarBotoneraListado();
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },

    cerrarModal() {
      this.modalActive = false;
    },

    async cambiarBorrados() {
      this.mostrarBorrados=!this.mostrarBorrados; 
      await this.initializeDataTable();
    },

    activarBotoneraListado() {
      let filas = JSON.parse(localStorage.getItem("Pedidos"));
      const tableBody = document.querySelector('#Pedidos-list tbody');
      tableBody.addEventListener('click', (event) => {
        if (event.target.classList.contains('fa-edit')) {  // Se pulsa EDIT
          this.$nextTick(() => { 
            this.modalActive = true; 
            this.tipoModal='E'; 
            for (let row of filas.data) {   console.log('Edita',row.id,event.target.dataset.rel);
              if (row.id==event.target.dataset.rel) {   console.log('Entra');
                this.PedidoEditId=row.id;
                this.PedidoEditActivo=row.activo;
                this.PedidoEditEnlace=row.enlace;
                this.PedidoEditName=row.nombre;
                this.PedidoEditPrecio=row.precio;
                this.PedidoEditTipster=row.tipster;
                this.PedidoEditTipsterId=row.tipster_id;
              }
            }
          });
        }
      });
    },
    irInicio() {
      this.$router.push('/dashboard');
    }
  },

  async mounted() { 
    this.$store.state.showFooter = true;
    this.initializeDataTable();
  },

};
</script>



<style lang="scss" scoped>
.Pedidos {
  background-color: rgba(0, 176, 234, 0.5);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .modal-content {
    display: flex;
    flex-direction: column;

    h1,
    p {
      margin-bottom: 16px;
    }

    h1 {
      font-size: 32px;
    }

    p {
      font-size: 18px;
    }
  }
}
</style>
