<template>
    <div v-if="!claveOlvidada">
        <h5 class="mb-4">Inicie sesión</h5>
        
        <Form role="form" class="text-start" :validation-schema="schema" @submit="handleInicioSesion">

            <soft-field
                id="usuario"
                v-model="user.usuario"
                type="text"
                placeholder="Email"
                name="usuario"
                class="mb-4"
            />

            <soft-field
                id="password"
                v-model="user.password"
                type="password"
                placeholder="Clave"
                name="password"
                class="mb-4"
            />

            <p class="mb-0" @click="claveOlvidada=true" style="font-style:italic;cursor:pointer;">Olvidé mi clave</p>

            <div class="mt-2">
                <soft-button
                    class="my-2 mb-2 "
                    full-width
                    color="tipster"
                    :is-disabled="loading ? true : false"
                    >
                    <span
                        v-if="loading"
                        class="spinner-border spinner-border-sm"
                    ></span>
                    <span v-else class="text-sm">Acceder</span>
                </soft-button>
            </div>
        </Form>
    </div>

    <div v-if="claveOlvidada">
        <h5 class="mb-4">Recupere su clave:</h5>

        <Form role="form2" class="text-start" :validation-schema="schema2" @submit="handleClaveOlvidada">

            <soft-field
                id="usuario"
                v-model="emailOlvidado"
                type="text"
                placeholder="Email"
                name="emailOlvidado"
                class="mb-4"
                :readonly="esperandoCodigo!='A'"
            />

            <div v-if="esperandoCodigo=='B'"><!--  A la espera de que tecle el código -->
                <soft-field
                    id="codigo"
                    v-model="codigoOlvidado"
                    type="number"
                    placeholder="Código recibido"
                    name="codigoOlvidado"
                    class="mb-4" 
                />
            </div>

            <div v-if="esperandoCodigo=='C'"><!--  A la espera de que tecle el código -->
                <soft-field
                    id="newpassword1"
                    v-model="newPassword1"
                    type="password"
                    placeholder="Nueva clave"
                    name="newpassword1"
                    class="mb-4" 
                />

                <soft-field
                    id="newpassword2"
                    v-model="newPassword2"
                    type="password"
                    placeholder="Repita clave"
                    name="newpassword2"
                    class="mb-4" 
                />
            </div>

            <p class="mb-0" @click="()=>{claveOlvidada=false;esperandoCodigo='A';}" style="font-style:italic;cursor:pointer;">Ya recordé mi clave</p>

            <div class="mt-2">
                <soft-button
                    class="my-2 mb-2 "
                    full-width
                    color="tipster"
                    :is-disabled="loading ? true : false"
                    >
                    <span
                        v-if="loading"
                        class="spinner-border spinner-border-sm"
                    ></span>
                    <span v-else class="text-sm">Recuperar Clave</span>
                </soft-button>
            </div>
        </Form>
    </div>
  </template>
  
  <script>
  import SoftField from "@/components/SoftField.vue";
  import SoftButton from "@/components/SoftButton.vue";
  import showSwal from "/src/mixins/showSwal.js";
  import { Form } from "vee-validate";
  import * as yup from "yup";

  export default {
    name: "InicioSesionForm",
    components: {
        SoftField,
        SoftButton,
        Form,
    },
    props: {
        esVisible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        const schema = yup.object().shape({
            usuario: yup.string().required("Debe indicar su usuario!"),
            password: yup.string().required("Debe indicar su clave!"),
        });
        const schema2 = yup.object().shape({
            emailOlvidado: yup.string().required("Debe indicar su email!"),
        });
        const user = {
            usuario: "",
            password: "",
        };
        return {
            loading: false,
            claveOlvidada: false,
            esperandoCodigo: 'A',
            emailOlvidado: '',
            codigoOlvidado: '',
            newPassword1: '',
            newPassword2: '',
            user,
            schema,
            schema2
        };
    },
    watch: {
        esVisible(abriendo) { 
            if (abriendo) {
                this.loading = false,
                this.claveOlvidada = false,
                this.esperandoCodigo = 'A';
                this.emailOlvidado = '';
                this.codigoOlvidado = '';
                this.newPassword1 = '';
                this.newPassword2 = ''; 
                this.user = {
                    usuario: "",
                    password: "",
                };               
            }
        }
    },
    emits: ['cerrar-modal'],
    methods: {
      async handleInicioSesion() {
        // Aquí puedes realizar acciones cuando se envía el formulario
        //console.log('Formulario enviado:', this.user);
        this.loading = true;
        try {
            await this.$store.dispatch("auth/login", this.user);

            // Obtengo el nombre del perfil para mostrar en cabecera
            await this.$store.dispatch("profile/getProfile");
            const resp = this.$store.getters["profile/profile"]; 
            localStorage.setItem("usuarioNombre",resp.name);
            localStorage.setItem("usuarioEmail",resp.email);
            localStorage.setItem("usuarioRolId",resp.rol_id);
            localStorage.setItem("usuarioTipsterId",resp.tipster_id);

            if (resp.rol_id==3) {
                this.$router.push("/profile");
            }
            else {
                this.$router.push("/dashboard");
            }
            
        } catch (error) {
            showSwal.methods.showSwal({
            type: "error",
            message: "Datos de acceso erróneos",
            });
            this.loading = false;
        }
      },
      async handleClaveOlvidada() {
        this.loading = true;
        try {
            if (this.esperandoCodigo=='A') {    // Se le envía un código de acceso
                await this.$store.dispatch("auth/claveOlvidada", { email: this.emailOlvidado } );
                this.loading = false;
                const resp = JSON.parse(localStorage.getItem("respClaveOlvidada"));
                if (resp.res=='OK') {
                    showSwal.methods.showSwal({
                        type: "success",
                        message: resp.mensaje,
                        width: 400,
                    });
                    this.esperandoCodigo = 'B';
                }
            }
            else if (this.esperandoCodigo=='B') {      // Se introduce el código de acceso y se valida
                await this.$store.dispatch("auth/codigoRecuperado", { email: this.emailOlvidado, codigo: this.codigoOlvidado } );
                this.loading = false;
                const resp = JSON.parse(localStorage.getItem("respCodigoRecuperado"));
                if (resp.res=='OK') {
                    this.esperandoCodigo = 'C';
                }
                else {
                    showSwal.methods.showSwal({
                        type: "error",
                        message: resp.mensaje,
                        width: 400,
                    });
                }
            }
            else if (this.esperandoCodigo=='C') {      // Se guarda la nueva clave
                if (this.newPassword1!=this.newPassword2) {
                    this.loading = false;
                    showSwal.methods.showSwal({
                        type: "error",
                        message: "Las claves indicadas deben coincidir",
                        width: 400,
                    });
                }
                else {
                    await this.$store.dispatch("auth/establecerClave", { email: this.emailOlvidado, codigo: this.codigoOlvidado, clave: this.newPassword1 } );
                    this.loading = false;
                    const resp = JSON.parse(localStorage.getItem("respEstablecerClave"));
                    if (resp.res=='OK') {
                        showSwal.methods.showSwal({
                            type: "success",
                            message: resp.mensaje,
                            width: 400,
                        });
                        this.esperandoCodigo = 'A';
                        this.user = {
                            usuario: this.emailOlvidado,
                            password: this.newPassword1,
                        };  
                        this.handleInicioSesion();
                    }
                }
            }
        } catch (error) {
            showSwal.methods.showSwal({
                type: "error",
                message: "Se ha producido un error",
            });
            this.loading = false;
        }
      }
    },
  };
  </script>
  
  <style scoped>
  /* Estilos específicos del componente MyForm */
  .boton {

  }
  </style>