<template>
    <p>Indícanos los siguientes datos:</p>
    <Form role="form"
          class="text-start"
          :validation-schema="schema"
          @submit="handleAltaRegistro"
          >
        <!--<label for="nombre">Nombre</label>-->
        <soft-field
            id="nombre"
            v-model="user.name"
            type="text"
            placeholder="Indique su nombre"
            name="nombre"
            class="mb-2"
            largo="80"
        />

        <!--<label for="email">Email</label>-->
        <soft-field
            id="email"
            v-model="user.email"
            type="email"
            placeholder="Indique su email"
            name="email"
            class="mb-2"
        />

        <div class="mt-5">
        <soft-switch 
            id="indacepto" 
            name="indacepto" 
            @change="cambiarAceptacion()" 
            style="display:inline-block;"
        />
        <label for="enviar-email" class="label-switch">Acepto el tratamiento informatizado de la presente información necesaria para la prestación de servicios y sus tareas derivadas</label>
        </div>

        <soft-button
            class="float-end mt-3 px-5"
            color="tipster"
            :is-disabled="loading ? true : false"
            >
            <span
                v-if="loading"
                class="spinner-border spinner-border-sm"
            ></span>
            <span v-else class="text-sm" style="font-weight:normal;font-size:1.1rem!important">Confirmar</span>
        </soft-button>
    </Form>
</template>
  
<script>
  import SoftField from "@/components/SoftField.vue";
  import SoftButton from "@/components/SoftButton.vue";
  import SoftSwitch from "@/components/SoftSwitch.vue";
  import showSwal from "/src/mixins/showSwal.js";
  import { Form } from "vee-validate";
  import * as yup from "yup";

  export default {
    name: "AltaUsuarioForm",
    components: {
        SoftField,
        SoftButton,
        SoftSwitch,
        Form,
    },
    data() {
        const schema = yup.object().shape({
            email: yup.string().required("El email es necesario!").email("Debe indicar un email válido"),
            nombre: yup.string().required("El nombre es necesario!").max(80,'El tamaño máximo es de 80 caracteres'),
        });
        const indacepto = false;
        return {
            loading: false,
            user:{
                email: '',
                name: ''
            },
            indacepto,
            schema
        };
    },
    emits: ['volver-registro'],
    methods: {
      async handleAltaRegistro() {
        if (!this.indacepto) {
            showSwal.methods.showSwal({
                type: "error",
                message: "Debe aceptar las condiciones de prestación de servicio",
                width: 600,
            });
        }
        else {
            console.log('Formulario enviado:', this.user);
            this.loading = true;
            try {
                await this.$store.dispatch("auth/register", this.user);
                this.loading = false;
                const resp = JSON.parse(localStorage.getItem("respRegistraUsuario"));
                if (resp.res=='OK') {
                    showSwal.methods.showSwal({
                        type: "success",
                        message: "Hemos registrado su usuario. Su clave de acceso es " + resp.clave,
                        width: 600,
                    });
                    this.$emit('volver-registro');
                }
                else {
                    showSwal.methods.showSwal({
                        type: "error",
                        message: resp.mensaje,
                        width: 500
                    });               
                }
            } catch (error) {
                this.loading = false;
                showSwal.methods.showSwal({
                    type: "error",
                    message: "Se ha producido un error",
                    width: 500,
                });
            }
        }
      },
      cambiarAceptacion() {
        this.indacepto = !this.indacepto;
      } 

    },
  };
  </script>
  
  <style scoped>
  /* Estilos específicos del componente MyForm */
  .label-switch {width: calc(100% - 3.5rem);float:right;margin:0;}
  </style>