<template>
  <nav class="" data-scroll="true">
    <div class="cabecera" id="navcabecera">
      <div class="left">
        <i class="fa me-xl-4 icono-lateral" :class="this.$store.state.mostrarMenuLateral ? 'fa-times' : 'fa-bars'" aria-hidden="true" @click="activarMenu"></i>
        <router-link class="nav-link m-0 navbar-brand" :to="{name: 'Profile'}">
          <img :src="logo" class="navbar-brand-img h-100" alt="Tipsterfy" />
        </router-link>
      </div>
      <div class="right" v-if="loggedIn">
        <p class="estilo-trabajador"><i class="fa fa-user me-sm-1" aria-hidden="true"></i> {{ trabajadorTxt }}</p>
        <a class="px-0 nav-link font-weight-bold text-body desconectar" @click="logoutUser">
          <i class="fa fa-power-off me-sm-1" aria-hidden="true"></i>
        </a>
      </div>
      <div class="right" v-if="!loggedIn">
        <p class="estilo-trabajador"><i class="fa fa-user me-sm-1" aria-hidden="true" @click="loginUser"></i> {{ trabajadorTxt }}</p>
      </div>
    </div>
  </nav>
</template>

<script>
import logo from "@/assets/img/tipsterfy.png";

export default {
  name: "NavCabecera",
  data() {
    return {
      logo,
      trabajadorTxt: localStorage.getItem("usuarioNombre"),
    };
  },
  computed: {
    loggedIn() {
      return this.$store.getters["auth/loggedIn"];
    }
  },
  created() {
    this.$store.state.mostrarMenuLateral = false;
    document.getElementById("app").classList.remove("rtl");
  },
  methods: { 
    async logoutUser() {
      try {
        await this.$store.dispatch("auth/logout");
        localStorage.removeItem("pedidos");
        localStorage.removeItem("productos");
        localStorage.removeItem("usuarios");
        localStorage.removeItem("tipsters");
        localStorage.removeItem("usuarioEmail");
        localStorage.removeItem("usuarioNombre");
        localStorage.removeItem("usuarioRolId");
        localStorage.removeItem("usuarioTipsterId");
      } finally {
        this.$router.push("/login");
      }
    },
    async loginUser() {
      this.$router.push("/login");
    },
    handleScroll() {  console.log('Scroll ', window.scrollY, window.innerWidth);
      if (window.scrollY > 0 || window.innerWidth < 650) {
        document.body.classList.add("fijar-cabecera");
      } else {
        document.body.classList.remove("fijar-cabecera");
      }
    },
    activarMenu() {
      if (this.$store.state.mostrarMenuLateral) {
        document.getElementById("app").classList.remove("rtl");
      }
      else {
        document.getElementById("app").classList.add("rtl");
      }
      this.$store.state.mostrarMenuLateral = !this.$store.state.mostrarMenuLateral;
    }
  },
  components: {
  },
  updated() {
  },
  mounted() {
    this.handleScroll();
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style>
.cabecera{
    height:8vh;max-height:80px;min-height:45px;overflow:hidden;border:0;
    width: 100%;transition: all 0.3s ease;color: #fff;background-color: #203353;border-color: #203353;
    & :hover {background-color:var(--bs-dark)!important;}
    > .left, > .right{height:100%;display:flex;align-items:center;float:left;}
    > .left > .navbar-brand {margin:5px 12px !important;display:inline-block;height:calc(100% - 10px);padding:0px;}
    > .left > p {display:inline-block;font-size:26px;margin:0;}
    > .right {float:right;padding-right:15px;}
    > .right > a > i {color:white;margin-right:10px!important;}
    > .right > a > span {color:white;}
    .desconectar {cursor:pointer;}
    .configuracion {padding:0;margin:0;width:50px;text-align: center;}
    .estilo-trabajador {margin:0 15px;font-weight:600;transition: all 1s ease;}
}
.fijar-cabecera .cabecera {
    transform: translateY(0);
    z-index: 999;
    position: fixed;
    top: 0;
    height: 3rem;
    .estilo-trabajador {
        margin: 0 5px;
        font-weight: 400;
        font-size: 0.85rem;
    }
}
@media (max-width: 650px) {
  .fijar-cabecera {
    .main-content {
      top:60px;
    }
  }
}
.main-content {
  margin-top: 0.5rem;
  margin-bottom: 2.5rem;
}
.icono-lateral {
  font-size: 1.6rem;
  margin-left: 15px;
  cursor: pointer;
}
@media (min-width: 1200px) {
.icono-lateral {display:none;}
}
</style>