<template>
  <div>
    <div v-if="loading" class="text-center">
      <p>Cargando...</p> <!-- Puedes personalizar esto con un spinner o un mensaje -->
    </div>
    <div v-else>
      <div class="p-3 pb-0 card-header">
        <h6>{{ title }}</h6>
        <p v-if="description" class="text-sm" v-html="description" />
      </div>
      <div class="p-3 card-body">
        <div class="chart">
          <canvas :id="id" class="chart-canvas" :height="height"></canvas>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";

export default {
  name: "GradientLineChart",
  props: {
    id: {
      type: String,
      required: true,
    },
    height: {
      type: String,
      default: "300",
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    chart: {
      type: Object,
      required: true,
      labels: Array,
      datasets: {
        type: Array,
        label: String,
        data: Array,
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    this.renderChart();
  },

  watch: {
    chart: {
      immediate: true,
      handler() {
        this.renderChart();
      },
    },
  },

  methods: {
    renderChart() {
      this.$nextTick(() => {
        const canvasElement = document.getElementById(this.id);
        if (!canvasElement) return; // Si el canvas no está disponible, no hacer nada

        const ctx = canvasElement.getContext("2d");

        var gradientStroke1 = ctx.createLinearGradient(0, 230, 0, 50);
        gradientStroke1.addColorStop(1, "rgba(203,12,159,0.2)");
        gradientStroke1.addColorStop(0.2, "rgba(72,72,176,0.0)");
        gradientStroke1.addColorStop(0, "rgba(203,12,159,0)");

        let chartStatus = Chart.getChart(this.id);
        if (chartStatus != undefined) {
          chartStatus.destroy();
        }

        new Chart(ctx, {
          type: "line",
          data: {
            labels: this.chart.labels,
            datasets: this.chart.datasets,
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
            },
            interaction: {
              intersect: false,
              mode: "index",
            },
            scales: {
              y: {
                grid: {
                  drawBorder: false,
                  display: true,
                  drawOnChartArea: true,
                  drawTicks: false,
                  borderDash: [5, 5],
                },
                ticks: {
                  display: true,
                  padding: 10,
                  color: "#b2b9bf",
                  font: {
                    size: 11,
                    family: "Nunito Sans",
                    style: "normal",
                    lineHeight: 2,
                  },
                },
              },
              x: {
                grid: {
                  drawBorder: false,
                  display: false,
                  drawOnChartArea: false,
                  drawTicks: false,
                  borderDash: [5, 5],
                },
                ticks: {
                  display: true,
                  color: "#b2b9bf",
                  padding: 20,
                  font: {
                    size: 11,
                    family: "Nunito Sans",
                    style: "normal",
                    lineHeight: 2,
                  },
                },
              },
            },
          },
        });
      });
    },
  },
};
</script>