import moment from 'moment';

export const globalFunctionsMixin = {
    methods: {
      formatDate(date,formato = 'DD/MM/YYYY') {
        return moment(date).format(formato); 
      },
      formatoImporte(valor,divisa) {
        if (typeof valor !== 'number') {
          valor = parseFloat(valor);
        }        
        if (isNaN(valor)) {
          return '0.00';
        } 
        let textoDivisa = '';
        if (divisa) {
          textoDivisa = '€';
        }
        return valor.toLocaleString('es-ES', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }) + textoDivisa;
      }
      // Agrega otras funciones globales aquí
    },
};