<template>
    <h5>Generar clave aleatoria</h5>
    <Form role="form"
          class="text-start"
          :validation-schema="schema"
          @submit="handleCambioClaveUsuario"
          > 
        <!--<label for="username">Usuario</label>-->
        <soft-field
            id="username"
            v-model="usuarioEditusername"
            type="text"
            placeholder="Usuario"
            name="username"
            class="mb-2"
            largo="30"
            readonly="readonly"
        />

        <!--<label for="email">Email</label>-->
        <soft-field
            id="email"
            v-model="usuarioEditemail"
            type="email"
            placeholder="Email"
            name="email"
            class="mb-2"
            readonly=true
        />
        
        <div class="mt-5">
        <soft-switch 
            id="enviar-email" 
            name="enviar-email" 
            @change="cambiarDinamico()" 
            style="display:inline-block;"
        />
        <label for="enviar-email">Enviar clave por email</label>
        </div>
  
        <soft-button
            class="float-end mt-3 px-5"
            color="tipster"
            :is-disabled="loading ? true : false"
            >
            <span
                v-if="loading"
                class="spinner-border spinner-border-sm"
            ></span>
            <span v-else class="text-sm" style="font-weight:normal;font-size:1.1rem!important">Confirmar</span>
        </soft-button>
    </Form>
  </template>
  
  <script>
  import SoftField from "@/components/SoftField.vue";
  import SoftButton from "@/components/SoftButton.vue";
  import SoftSwitch from "@/components/SoftSwitch.vue";
  import showSwal from "/src/mixins/showSwal.js";
  import { Form } from "vee-validate";
  //import * as yup from "yup";

  export default {
    name: "AltaUsuarioForm",
    components: {
        SoftField,
        SoftButton,
        SoftSwitch,
        Form,
    },
    props: {
        usuarioId: Number,
        usuarioUsername: String,
        usuarioEmail: String
    },
    data() {
    /*  const schema = yup.object().shape({
            email: yup.string().required("El email es necesario!").email("Debe indicar un email válido"),
            username: yup.string().required("El usuario es necesario!").max(30,'El tamaño máximo es de 30 caracteres').matches(/^[a-zA-Z0-9.-]+$/, 'El usuario contiene caracteres no permitidos'),
            nombre: yup.string().required("El nombre es necesario!").max(80,'El tamaño máximo es de 80 caracteres'),
            rol_id: yup.number().positive('Debe indicar un rol')
        }); */
        return {
            loading: false,
            user:{},
        //    schema,
            usuarioEditusername: this.usuarioUsername,
            usuarioEditemail: this.usuarioEmail,
            indemail: false,
        };
    },
    emits: ['cerrar-modal'],
    methods: {
      async handleCambioClaveUsuario() {
        // Aquí puedes realizar acciones cuando se envía el formulario
        console.log('Formulario enviado:', this.usuarioId);
        this.loading = true;
        try {
            await this.$store.dispatch("auth/generarClaveUsuario", {id: this.usuarioId, indemail: this.indemail});
            this.loading = false;
            const resp = JSON.parse(localStorage.getItem("respgenerarClaveUsuario"));
            if (resp.res=='OK') {
                showSwal.methods.showSwal({
                    type: "success",
                    message: resp.mensaje,
                    width: 600,
                    timer: 12000
                });
                this.$emit('cerrar-modal');
            }
            else {
                showSwal.methods.showSwal({
                    type: "error",
                    message: resp.mensaje,
                    width: 500
                });               
            }
        } catch (error) {
            this.loading = false;
            showSwal.methods.showSwal({
                type: "error",
                message: "Se ha producido un error",
                width: 500,
            });
        }
      },
      cambiarDinamico() {
        this.indemail = !this.indemail;
      }
    },
  };
  </script>
  
  <style scoped>
  /* Estilos específicos del componente MyForm */
  </style>