<!-- src/components/PaymentComponent.vue -->
<template>
    <div class="zona-pago mt-5">
      <p class="mb-2">Realiza tu pago con Stripe:</p>
  
      <form id="payment-form" @submit.prevent="handlePayment">

        <div class="recuadro">
          <div><!-- Número de tarjeta -->
            <label for="card-number" class="mb-0">Número de tarjeta</label>
            <div id="card-number"></div>
          </div>

          <div><!-- Fecha de vencimiento -->
          <!--<label for="card-expiry">Fecha de vencimiento</label>-->
            <div id="card-expiry"></div>
          <!--</div>-->
          <!-- CVC -->
          <!--<div>-->
          <!--<label for="card-cvc">CVC</label>-->
            <div id="card-cvc"></div>
          </div>
        </div>

        <p v-if="paymentError" class="error-stripe mt-4 mb-0">{{ paymentError }}</p>
        <soft-button
            class="float-end mt-4 px-5"
            color="tipster"
            :is-disabled="isProcessing ? true : false"
            >
            <span
                v-if="isProcessing"
                class="spinner-border spinner-border-sm"
            ></span>
            <span v-else class="text-sm" style="font-weight:normal;font-size:1.1rem!important">Pagar &nbsp;<i class="fa fa-check" aria-hidden="true"></i></span>
        </soft-button>
      </form>
    </div>
  </template>
  
  <script>
  import SoftButton from "@/components/SoftButton.vue";
  import showSwal from "/src/mixins/showSwal.js";
  export default {
    props: {
      clientSecret: {
        type: String,
        required: true,
      },
      publicKey: {
        type: String,
        required: true,
      }
    },
    components: {
      SoftButton
    },
    data() {
      return {
        stripe: null,
        elements: null,
        isProcessing: false,
        cardNumberElement: null,
        cardExpiryElement: null,
        cardCvcElement: null,
        paymentError: null,
      };
    },
    mounted() { console.log('Monta componente PaymentStripe con ' + this.publicKey);
      // Inicializar Stripe y los elementos de tarjeta
      this.stripe = window.Stripe(this.publicKey); // Inserta tu clave pública
      this.elements = this.stripe.elements();

      // Aplica estilos personalizados al elemento de la tarjeta
      const style = {
        base: {
          color: '#32325d',
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a',
        },
      };

      // Crea elementos individuales
      this.cardNumberElement = this.elements.create('cardNumber', { style });
      this.cardExpiryElement = this.elements.create('cardExpiry', { style });
      this.cardCvcElement = this.elements.create('cardCvc', { style });

      // Monta los elementos en sus respectivos divs
      this.cardNumberElement.mount('#card-number');
      this.cardExpiryElement.mount('#card-expiry');
      this.cardCvcElement.mount('#card-cvc');
    },
    emits: ['cerrar-componente'],
    methods: {
      async handlePayment() {   console.log('Confirma Pago ' + this.clientSecret);
        this.isProcessing = true;
        this.paymentError = null;
  
        // Confirmar el pago con el client_secret recibido como prop
        const { error, paymentIntent } = await this.stripe.confirmCardPayment(this.clientSecret, {
          payment_method: {
            card: this.cardNumberElement,
          },
        });
  
        if (error) {
          // Manejar el error del pago
          this.paymentError = error.message;
          this.isProcessing = false;

          showSwal.methods.showSwal({
            type: "error",
            message: error.message,
          });
        } 
        else if (paymentIntent.status === 'succeeded') {
          // El pago fue exitoso
          this.isProcessing = false;

          showSwal.methods.showSwal({
              type: "success",
              message: "Pago realizado con éxito",
              width: 600,
          });

          await this.$store.dispatch("auth/confirmarPagado", { clientSecret: this.clientSecret, paymentId: paymentIntent.id  }); 
          const response = JSON.parse(localStorage.getItem("respconfirmarPagado"));
          if (response.res=='OK') {
            console.log('Confirmado pago ' + response.res + ' ' + response.enlace);
            this.$emit('cerrar-componente', response.res, response.enlace);
          } 
          else {
            console.log('Error notificando confirmacion de pago');
            this.$emit('cerrar-componente', response.res, response.message);
          }  

        }
      },

    },
  };
  </script>
  
  <style scoped>
  .zona-pago {
    p {
      font-weight:bold;
      text-align:left;
      font-size:1.1rem;
    }
  }
  .error-stripe {
    display: block;
    font-size: 0.9rem!important;
    color:red;
  }
  #card-number {
    border: 1px solid #999;
    padding: 9px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  #card-expiry {
    border: 1px solid #999;
    padding: 9px;
    border-bottom-left-radius: 12px;
    width: 50%;
    display: inline-block;
    margin-top: -1px;
  }
  #card-cvc {
    border: 1px solid #999;
    padding: 9px;
    border-bottom-right-radius: 12px;
    width: 50%;
    display: inline-block;
    margin-top: -1px;
  }
  </style>