import authService from "../services/auth.service";

const user = JSON.parse(localStorage.getItem("userF"));
//const initialState = user ? { loggedIn: true } : { loggedIn: false };

export const auth = {
  namespaced: true,
  state: {
    loggedIn: user ? true : false
  },
  actions: {
    async login({ commit }, userData) {
      await authService.login(userData);
      commit("logMeIn");
    },

    async logout({ commit }) {
      try {
        await authService.logout();
      } finally {
        commit("logMeOut");
      }
    },

    async register(context, userData) {
      await authService.register(userData);
    },

    async claveOlvidada(context, userData) {
      await authService.claveOlvidada(userData);
    },

    async codigoRecuperado(context, userData) {
      await authService.codigoRecuperado(userData);
    },

    async validaCodigo({ commit }, userData) {
      const resp = await authService.validaCodigo(userData);
      if (resp.data['res']=='OK') {  
        commit("logMeIn");    // Conectado
      }
    },

    async establecerClave(context, userData) {
      await authService.establecerClave(userData);
    },

    async generaPedido(context, pedido) {
      await authService.generaPedido(pedido);
    },

    async confirmarPagado(context, pedido) {
      await authService.confirmarPagado(pedido);
    },

    // TIPSTERS
    async tipsters(context,tipster) {
      await authService.tipsters(tipster);
    },

    async listaTipsters() {
      await authService.listaTipsters();
    },

    async guardaTipster(context, tipster) { 
      await authService.guardaTipster(tipster);
    },

    async borraTipster(context, tipster) { 
      await authService.borraTipster(tipster);
    },


    // USUARIOS
    async usuarios(context,usuario) {
      await authService.usuarios(usuario);
    },

    async guardaUsuario(context, usuario) { 
      await authService.guardaUsuario(usuario);
    },

    async borraUsuario(context, usuario) { 
      await authService.borraUsuario(usuario);
    },

    async generarClaveUsuario(context, usuario) { 
      await authService.generarClaveUsuario(usuario);
    },


    // PRODUCTOS
    async productos(context,producto) {
      await authService.productos(producto);
    },

    async guardaProducto(context, producto) { 
      await authService.guardaProducto(producto);
    },

    async borraProducto(context, producto) { 
      await authService.borraProducto(producto);
    },

    async consultaProducto(context, uuid) { 
      await authService.consultaProducto(uuid);
    },
 
    async validaEmailUsuario(context, email) { 
      await authService.validaEmailUsuario(email);
    },

    // PEDIDOS
    async pedidos(context,pedido) {
      await authService.pedidos(pedido);
    },

    // ESTADISTICAS
    async estadisticas() {
      await authService.estadisticas();
    },
  },
  mutations: {
    logMeIn(state) {
      state.loggedIn = true;
    },
    logMeOut(state) {
      state.loggedIn = false;
    }
  },

  getters: {
    loggedIn(state) {
      return state.loggedIn;
    },
  },
};
