<template>
  <div class="card mb-5">
    <div class="card-header pb-0">
      <div class="row">
        <div class="col-lg-6 col-7">
          <h6>{{ title }}</h6>
          <p class="text-sm mb-0">
            <i class="fa fa-check text-info" aria-hidden="true"></i>
            <span class="font-weight-bold ms-1">{{ cuantos }}</span> activos los últimos 7 días
          </p>
        </div>
      </div>
    </div>
    <div class="card-body px-0 pb-2 mb-4">
      <div class="table-responsive">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" width="50%">Tipster</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Ventas</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="v in ventas" :key="v.nombre">
              <td>
                <div class="d-flex px-2 py-1">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ v.nombre }}</h6>
                  </div>
                </div>
              </td>
              <td class="align-middle">
                <div class="d-flex align-items-center justify-content-center">
                  <span class="text-xs font-weight-bold mx-2">{{ v.total }} €</span>
                  <div style="width:calc(100% - 90px);">
                    <soft-progress
                      color="info"
                      class="mx-auto"
                      variant="gradient"
                      :percentage="v.total"
                    />
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import SoftProgress from "@/components/SoftProgress.vue";

export default {
  name: "projects-card",
  props: {
    title: {
      type: String,
      default: "",
    },
    cuantos: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    ventas: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
    };
  },
  components: {
    SoftProgress,
  },
  mounted() {
    setTooltip();
  },
};
</script>
