<template>
  <div
    class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100"
    id="sidenav-collapse-main"
  >
    <ul class="navbar-nav mt-4">
      <li class="nav-item px-2" v-if="rolId<3">
        <sidenav-collapse navText="Panel" :to="{ name: 'Dashboard' }" @click="cerrarMenu()">
          <template #icon>
            <shop width="18px" height="18px"/>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item px-2">
        <sidenav-collapse navText="Mi usuario" :to="{ name: 'Profile' }" @click="cerrarMenu()">
          <template #icon>
            <customer-support width="18px" height="18px"/>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item px-2" v-if="rolId==1">
        <sidenav-collapse navText="Tipsters" :to="{ name: 'Tipsters' }" @click="cerrarMenu()">
          <template #icon>
            <spaceship width="18px" height="18px"/>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item px-2" v-if="rolId<3">
        <sidenav-collapse navText="Usuarios" :to="{ name: 'Usuarios' }" @click="cerrarMenu()">
          <template #icon>
            <switches width="18px" height="18px"/>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item px-2" v-if="rolId<3">
        <sidenav-collapse navText="Productos" :to="{ name: 'Productos' }" @click="cerrarMenu()">
          <template #icon>
            <box3d width="18px" height="18px"/>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item px-2">
        <sidenav-collapse navText="Pedidos" :to="{ name: 'Pedidos' }" @click="cerrarMenu()">
          <template #icon>
            <credit-card width="18px" height="18px"/>
          </template>
        </sidenav-collapse>
      </li>
      <!--
      <li class="mt-3 nav-item px-2" v-if="rolId==1">
        <h6 class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6" :class="this.$store.state.isRTL ? 'me-4' : 'ms-2'">
          TEMPLATES
        </h6>
      </li>
      <li class="nav-item" v-if="rolId==1">
        <sidenav-collapse navText="Tables" :to="{ name: 'Tables' }">
          <template #icon>
            <office width="18px" height="18px"/>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="rolId==1">
        <sidenav-collapse navText="Billing" :to="{ name: 'Billing' }">
          <template #icon>
            <credit-card width="18px" height="18px"/>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="rolId==1">
        <sidenav-collapse navText="Virtual Reality" :to="{ name: 'Virtual Reality' }">
          <template #icon>
            <box3d width="18px" height="18px"/>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="rolId==1">
        <sidenav-collapse navText="RTL" :to="{ name: 'Rtl' }">
          <template #icon>
            <settings width="18px" height="18px"/>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="rolId==1">
        <sidenav-collapse navText="Profile" :to="{ name: 'ProfileTemplate' }">
          <template #icon>
            <customer-support width="18px" height="18px"/>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="rolId==1">
        <sidenav-collapse navText="Sign In" :to="{ name: 'Sign In' }">
          <template #icon>
            <document width="18px" height="18px"/>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="rolId==1">
        <sidenav-collapse navText="Sign Up" :to="{ name: 'Sign Up' }">
          <template #icon>
            <spaceship width="18px" height="18px"/>
          </template>
        </sidenav-collapse>
      </li>-->
    </ul>
  </div>
</template>
<script>
import SidenavCollapse from "./SidenavCollapse.vue";
//import SidenavCard from "./SidenavCard.vue";
import Shop from "../../components/Icon/Shop.vue";
//import Office from "../../components/Icon/Office.vue";
import CreditCard from "../../components/Icon/CreditCard.vue";
import Box3d from "../../components/Icon/Box3d.vue";
import CustomerSupport from "../../components/Icon/CustomerSupport.vue";
import Switches from "../../components/Icon/Switches.vue";
//import Document from "../../components/Icon/Document.vue";
import Spaceship from "../../components/Icon/Spaceship.vue";
//import Settings from "../../components/Icon/Settings.vue";

export default {
  name: "SidenavList",
  props: {
    cardBg: String,
  },
  data() {
    return {
      title: "Soft UI Dashboard PRO",
      controls: "dashboardsExamples",
      isActive: "active",
      rolId: localStorage.getItem("usuarioRolId")
    };
  },
  components: {
    SidenavCollapse,
  //  SidenavCard,
    Shop,
  //  Office,
    CreditCard,
    Box3d,
    CustomerSupport,
    Switches,
  //  Document,
    Spaceship,
  //  Settings,
  },
  methods: {
    cerrarMenu() { 
      this.$store.state.mostrarMenuLateral = false;
      document.getElementById("app").classList.remove("rtl");
    }
  },
};
</script>
